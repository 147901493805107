html,
body {
  height: 100%;
  background-color: hsl(219, 9%, 100%);
}

body {
  text-align: center;

  color: #333;
  /*box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);*/
}

.container{
	margin: auto;
	max-width: 1000px;
}
#heading{
	text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
}

/*#numbers{
	font-size: 20px;
}*/

.border-3 {
  border-width: 3px !important;
}

.border-at-top {
  border-top: 6px solid #40A4F4;
}

.mute-link {
  color: black;
}